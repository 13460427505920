import { useSSRSelector } from '../redux';
import {
  getVisibilityForFooter,
  getVisibilityForHeader,
} from '@/utils/logoAndNameHelpers';

/**
 * use this hook to get info about store logo and store name visibility in Header and Footer.
 * visibility is defined by merchant by using editor.
 */
const useStoreLogoOrNameVisibility = () => {
  const { store_logo_status, store_name_status } = useSSRSelector(
    (state) => state.storeReducer.store.theme
  );
  return {
    isStoreLogoInHeader: getVisibilityForHeader(store_logo_status),
    isStoreLogoInFooter: getVisibilityForFooter(store_logo_status),
    isStoreNameInHeader: getVisibilityForHeader(store_name_status),
    isStoreNameInFooter: getVisibilityForFooter(store_name_status),
  };
};

export default useStoreLogoOrNameVisibility;
