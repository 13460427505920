import React, { useEffect, useRef, useState } from 'react';
import areEqual from './util/arePropsEqual';
import { getGoogleMapUrl } from '@/utils/getGoogleMapUrl';

function FooterMap2(props) {
  const { storeData } = props;

  const [loadMap, setLoadMap] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setLoadMap(true);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef?.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    !!storeData?.address &&
    storeData?.address?.visibility === 1 &&
    !!storeData?.address?.latitude &&
    !!storeData?.address?.longitude && (
      <div className="Footer2__section" ref={containerRef}>
        <iframe
          width="100%"
          height="250"
          title="Address"
          loading="lazy"
          src={
            loadMap
              ? getGoogleMapUrl(
                  storeData?.address?.latitude,
                  storeData?.address?.longitude
                )
              : ''
          }
        />
      </div>
    )
  );
}

export default React.memo(FooterMap2, areEqual);
